import { Route, Routes } from 'react-router';
import './App.css';
import MyNav from './Components/MyNav/MyNav';
import Home from './Pages/Home/Home';
import MyFooter from './Components/Footer/MyFooter';
import FloatedPhone from './Components/FloatedPhoneNum/FloatedPhone';
import AboutUs from './Pages/AboutUs/AboutUs';
import Buildings from './Pages/Buildings/Buildings';
import TradePlaces from './Pages/TradePlaces/TradePlaces';
import FixAndClean from './Pages/FixAndClean/FixAndClean';
import FireSystem from './Pages/FireSystem/FireSystem';
import OwnsManage from './Pages/OwnsManage/OwnsManage';
import ContactUs from './Pages/ContactUs/ContactUs';
import Roads from './Pages/Roads/Roads';

function App() {

  return (
    <>
      <MyNav />

      <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/من_نحن' element={<AboutUs />}></Route>
        <Route path='/المباني_السكنية' element={<Buildings />}></Route>
        <Route path='/المواقع_التجارية' element={<TradePlaces />}></Route>
        <Route path='/صيانة_ونظافة_وتشغيل' element={<FixAndClean />}></Route>
        <Route path='/تركيب_أنظمة_الانذار_والحريق' element={<FireSystem />}></Route>
        <Route path='/ادارة_الأملاك' element={<OwnsManage />}></Route>
        <Route path='/الطرق' element={<Roads />}></Route>
        <Route path='/تواصل_معنا' element={<ContactUs />}></Route>
      </Routes>

      <FloatedPhone />

      <MyFooter />
    </>
  );
};

export default App;
