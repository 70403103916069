import React from 'react';
import "./MyNav.css";
import { NavLink, useNavigate } from 'react-router-dom';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { scrollingToTop } from '../../Functions/ScrollingToTop';

export default function MyNav() {
  const navigate = useNavigate();

  return (
    <Navbar expand="lg" className='position-absolute top-0 left-0 w-100 myNavBar'>
      <Container>
        <Navbar.Toggle data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample" />
        <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
          <div className="offcanvas-header">
              <h3 className="offcanvas-title" id="offcanvasRightLabel">
                درة التعمير
              </h3>
              <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div className="offcanvas-body px-3">
            <Nav className="me-auto navList h-100">

              <NavLink className="nav-link" onClick={()=> {
                navigate("/");
                scrollingToTop(0);
              }} data-bs-dismiss="offcanvas" aria-label="Close">الرئيسية</NavLink>

              <NavLink className="nav-link" onClick={()=> {
                navigate("/من_نحن");
                scrollingToTop(0);
              }} data-bs-dismiss="offcanvas" aria-label="Close">من نحن</NavLink>

              <div className='innerContent'>
                <NavLink className="nav-link">
                  مجالات العمل
                  <i className="fa-solid fa-sort-down pe-2"></i>
                </NavLink>
                <ul className='innerList'>

                  <li onClick={()=> {
                      navigate("/المباني_السكنية");
                      scrollingToTop(0);
                    }}>
                    <NavLink className="nav-link">المباني السكنية</NavLink>
                  </li>

                  <li onClick={()=> {
                      navigate("/المواقع_التجارية");
                      scrollingToTop(0);
                    }}>
                    <NavLink className="nav-link">المواقع التجارية</NavLink>
                  </li>

                  <li onClick={()=>{
                      navigate("/صيانة_ونظافة_وتشغيل");
                      scrollingToTop(0);
                    }}>
                    <NavLink className="nav-link">صيانة ونظافة وتشغيل</NavLink>
                  </li>

                  <li onClick={()=>{
                      navigate("/تركيب_أنظمة_الانذار_والحريق");
                      scrollingToTop(0);
                    }}>
                    <NavLink className="nav-link">تركيب أنظمة الانذار والحريق</NavLink>
                  </li>

                  <li onClick={()=>{
                      navigate("/ادارة_الأملاك");
                      scrollingToTop(0);
                    }}>
                    <NavLink className="nav-link">ادارة الأملاك</NavLink>
                  </li>

                  <li onClick={()=>{
                      navigate("/الطرق");
                      scrollingToTop(0)
                    }}>
                    <NavLink className="nav-link">الطرق</NavLink>
                  </li>

                </ul>
              </div>
              <div className="innerContentDropDown">
                <div className="dropdown">
                  <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <NavLink className="nav-link">
                      مجالات العمل
                      <i className="fa-solid fa-sort-down pe-2"></i>
                    </NavLink>
                  </button>
                  <ul className="dropdown-menu text-end">

                    <li onClick={()=> {
                      navigate("/المباني_السكنية");
                      scrollingToTop(0);
                    }} data-bs-dismiss="offcanvas" aria-label="Close">
                      <NavLink className="nav-link">المباني السكنية</NavLink>
                    </li>

                    <li onClick={()=> {
                      navigate("/المواقع_التجارية");
                      scrollingToTop(0);
                    }} data-bs-dismiss="offcanvas" aria-label="Close">
                      <NavLink className="nav-link">المواقع التجارية</NavLink>
                    </li>

                    <li onClick={()=>{
                      navigate("/صيانة_ونظافة_وتشغيل");
                      scrollingToTop(0);
                    }} data-bs-dismiss="offcanvas" aria-label="Close">
                      <NavLink className="nav-link">صيانة ونظافة وتشغيل</NavLink>
                    </li>

                    <li onClick={()=>{
                      navigate("/تركيب_أنظمة_الانذار_والحريق");
                      scrollingToTop(0);
                    }} data-bs-dismiss="offcanvas" aria-label="Close">
                      <NavLink className="nav-link">تركيب أنظمة الانذار والحريق</NavLink>
                    </li>

                    <li onClick={()=>{
                      navigate("/ادارة_الأملاك");
                      scrollingToTop(0);
                    }} data-bs-dismiss="offcanvas" aria-label="Close">
                      <NavLink className="nav-link">ادارة الأملاك</NavLink>
                    </li>

                    <li onClick={()=>{
                      navigate("/الطرق");
                      scrollingToTop(0)
                    }} data-bs-dismiss="offcanvas" aria-label="Close">
                      <NavLink className="nav-link">الطرق</NavLink>
                    </li>

                  </ul>
                </div>
              </div>

              <NavLink className="nav-link" onClick={()=>{
                navigate("/تواصل_معنا");
                scrollingToTop(0);
              }} data-bs-dismiss="offcanvas" aria-label="Close">تواصل معنا</NavLink>

            </Nav>
          </div>
        </div>
        <Navbar.Brand className='me-0'>
          <NavLink className="nav-link navImg" to="/">
            <img src="./Imgs/sec_1/logo_icon.png" alt="Page Logo" />
          </NavLink>
        </Navbar.Brand>
      </Container>
    </Navbar>
  )
}
