import React from 'react';
import "./EveryPageMainSection.css";

export default function EveryPageMainSection({heading,text,textHead,imgSrc}) {

    return (
        <div className='everyPageMainHeroSection position-relative'>
            <div className={`position-absolute everyPageMainCenterImage ${text?.length > 300 ? "mt-5 pt-5" : ""}`}>
                <img src="./Imgs/CenterLine.png" alt="Hero Section Center Line" className="w-100 h-100" />
            </div>
            <div className="position-absolute everyPageMainLayer">
                <img src="./Imgs/Untitled-1_0014_Layer-2.png" className='w-100 h-100' alt="Hero Section Background Layer" />
            </div>
            <div className="bottomLeftImg position-absolute">
                <img src="./Imgs/sec_1/logo battrn.png" className='h-100' alt="bottom left" />
            </div>
            <div className="container-fluid">
                <div className={`row justify-content-center align-items-center`}>
                    <div className={`col-lg-6 everyPageMainText d-flex flex-column align-items-center justify-content-center ${text?.length > 300 ? "mt-5 pt-5" : ""}`}>
                        <div className='text-center'>
                            <h1 className='everyPageMainHeroSectionHeading'>{heading ? heading : ""}</h1>
                        </div>
                        <div className="text-center mt-4 position-relative">
                            {textHead ?
                                <h3 className='fw-bolder'>
                                    {textHead}
                                </h3>
                                : ""
                            }
                            <p>
                                {text ? text.slice(0,500) : ""}
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-1">
                    </div>
                    <div className="col-lg-5 m-0 p-0 everyPageMainRightImage">
                        <img src={imgSrc ? imgSrc : ""} alt="Hero Section" className="w-100 h-100" />
                    </div>
                </div>
            </div>
        </div>
    );
};
