import React from 'react';
import "./FloatedPhone.css";
import { NavLink } from 'react-router-dom';

export default function FloatedPhone() {
    return (
        <>
            <NavLink to="tel:00996555540617" className='position-fixed floatedPhone'>
                <i className="fa-solid fa-phone-volume"></i>
            </NavLink>
            <NavLink className='position-fixed floatedPhone floatedWatsApp' to="https://wa.me/+966555540617">
                <i className="fa-brands fa-whatsapp"></i>
            </NavLink>
        </>
    )
}
