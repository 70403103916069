import React, { useEffect, useState } from 'react';
import "./ContactUs.css";
import AboutHeroSection from '../../Sections/ThirdHeroSectionAbout/AboutHeroSection';
import TopComponent from '../../Components/TopComponent/TopComponent';
import ContactForm from '../../Components/ContactForm/ContactForm';

export default function ContactUs() {
  const [loading,setLoading] = useState(true);

  useEffect(()=>{
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timeoutId);
  },[loading]);

  return (
    <>
      {
        loading ?
        <div className="loaderContainer">
          <div className="loader"></div>
        </div>
        :
        <>
        <AboutHeroSection 
        alt="Contact Us Main Hero Section"
        src="./Imgs/WhatsApp Image 2023-12-12 at 04.20.14_3205beb6.jpg"
        heading="تواصل معنا"
        text="تعتمد الشركة على استخدام وسائل تواصل متعددة ومتنوعة. فمن خلال الاجتماعات الدورية، يتم مناقشة التقدم في المشاريع وتحديد الأهداف الجديدة وتوزيع المهام. بالإضافة إلى ذلك، تستخدم الشركة التكنولوجيا المتقدمة مثل البريد الإلكتروني والتطبيقات المشتركة والدردشة الفورية لتيسير التواصل اليومي ."
        />
        <TopComponent />
        <ContactForm />
        <TopComponent/>
        </>
      }
    </>
  )
}
