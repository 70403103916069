import * as yup from "yup";

export const ContactSchema = yup.object().shape({
    name: yup
    .string()
    .required("Required"),
    email: yup
    .string()
    .required("Required"),
    subject: yup
    .string()
    .required("Required"),
    phone: yup
    .string()
    .required("Required"),
    message: yup
    .string()
    .required("Required")
});