import React from 'react';
import "./HeroSection.css";
import { Col, Row } from 'react-bootstrap';
import HomeSecSlider from '../../Components/HomeSecSlider/HomeSecSlider';

export default function HeroSection() {
    return (
        <div className='heroSection'>
            <div className="upperLeftImg position-absolute">
                <img src="./Imgs/sec_1/upper corner.png" alt="upper left" />
            </div>
            <div className="bottomLeftImg position-absolute">
                <img src="./Imgs/sec_1/logo battrn.png" className='h-100' alt="bottom left" />
            </div>
            <div className="centerBottomImg position-absolute">
                <img src="./Imgs/sec_1/shades for ahdaf.png" className='h-100' alt="center bottom" />
            </div>
            <div className="centerTopImg position-absolute">
                <img src="./Imgs/sec_1/Rectangle for hadafna.png" className='h-100' alt="center top" />
            </div>
            <h1 className="heroSectionText text-center">
                درة التعمير
                <br />
                للمقاولات العامة
            </h1>
            <div className='container-fluid heroSectionContainer'>
                <Row>
                    <Col lg={6} md={8} className='heroSectionColumnSLider'>
                        <HomeSecSlider />
                    </Col>
                    <Col lg={2} className='spacingColumn'></Col>
                    <Col lg={4} className='heroSectionColumn heroSectionRightColumn position-relative'>
                        <img src="./Imgs/photo/Untitled-1_02.png" className='mainImg' alt="hero Section" />
                        <div className="heroSectionColumnCenterimage position-absolute">
                            <img src="./Imgs/sec_1/pattern for photo.png" className='h-100' alt="column center" />
                        </div>
                        <div className="heroSectionColumnLeftImage d-md-none position-absolute">
                            <img src="./Imgs/sec_1/photo layout.png" className='h-100' alt="column left" />
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
