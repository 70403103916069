import React from 'react';
import "./MyFooter.css";
import { Col, Container, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import CopyWrite from '../CopyWrite/CopyWrite';



export default function MyFooter() {
  return (
    <div className='myFooter'>
      <Container>
        <Row className='pb-3'>
          <Col lg={4} md={6} className='text-end myFooterColumn text-light pb-md-4'>
            <h3>اتصل بنا :</h3>
            <ul className='d-flex flex-column mt-4'>
              <li className='phoneNum d-flex gap-3 align-items-center'>
                <i className="fa-solid fa-phone"></i>
                0555540617
              </li>
              <li className='footerEmail'>
                <span className='nav-link d-flex gap-3 align-items-center'>
                  <i className="fa-regular fa-envelope"></i>
                  Pro@dora-altaemir.com
                </span>
              </li>
              <li className='d-flex gap-3 footerSocialLinks'>
                <NavLink to="https://instagram.com/doraaltaemir?igshid=NzZlODBkYWE4Ng==" className="nav-link"><i className="fa-brands fa-instagram"></i></NavLink>
                <NavLink to="" className="nav-link"><i className="fa-brands fa-facebook"></i></NavLink>
                <NavLink to="" className="nav-link"><i className="fa-brands fa-twitter"></i></NavLink>
                <NavLink to="https://www.tiktok.com/@doraaltaemir?_t=8hZvdsDfW66&_r=1" className="nav-link"><i className="fa-brands fa-tiktok"></i></NavLink>
                <NavLink to="" className="nav-link"><i className="fa-brands fa-snapchat"></i></NavLink>
              </li>
            </ul>
          </Col>
          <Col lg={4} md={6} className='text-end myFooterColumn text-light pb-md-4'>
            <h3>مجالات العمل :</h3>
            <ul className='d-flex flex-column mt-4'>
              <li>
                <NavLink className="nav-link" to="/المباني_السكنية">المباني السكنية</NavLink>
              </li>
              <li>
                <NavLink className="nav-link" to="/المواقع_التجارية">المواقع التجارية</NavLink>
              </li>
              <li>
                <NavLink className="nav-link" to="/صيانة_ونظافة_وتشغيل">صيانة ونظافة وتشغيل</NavLink>
              </li>
              <li>
                <NavLink className="nav-link" to="/تركيب_أنظمة_الانذار_والحريق">تركيب أنظمة الانذار والحريق</NavLink>
              </li>
              <li>
                <NavLink className="nav-link" to="/ادارة_الأملاك">ادارة الأملاك</NavLink>
              </li>
            </ul>
          </Col>
          <Col lg={4} md={6} className='text-end myFooterColumn text-light pb-md-4'>
            <h3>درة التعمير :</h3>
            <ul className='d-flex flex-column mt-4'>
              <li>
                <NavLink className="nav-link" to="/">
                  الرئيسية
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" to="/من_نحن">
                  من نحن
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" to="/تواصل_معنا">
                  تواصل معنا
                </NavLink>
              </li>
            </ul>
          </Col>
        </Row>
        <CopyWrite />
      </Container>
    </div>
  )
}
