import React, { useState } from 'react'
import Swal from 'sweetalert2';
import { ContactSchema } from '../../Validation/ContactSchema';
import { useFormik } from 'formik';
import "./ContactForm.css";
import { baseURL } from '../../Functions/ScrollingToTop';

export default function ContactForm() {
  const [showErrors,setShowErrors] = useState(false);
  const [backEndErrors,setBackEndErrors] = useState(null);

  const onSubmit = async ( values , actions) => {
    const isValid = await ContactSchema.validate(values);
    console.log("ay_Haga")
    if(isValid){
      const res = await fetch(`${baseURL}/contact`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify(values)
      });
      const response = await res.json();
      console.log(response);
      if(response.status){
        setShowErrors(false);
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: `${response.message}`,
          showConfirmButton: false,
          timer: 1000
        });
        actions.resetForm();
      }else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong! .. please Try again'
        });
        setBackEndErrors({...response.errors});
      }
    }
  };

  const { values , errors , touched , handleBlur , handleChange , handleSubmit } = useFormik({
    initialValues:{
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: ""
    },
    validationSchema: ContactSchema,
    onSubmit,
  });

  return (
    <div className="contactForm">
      <div className='container'>
        <div className="row">
          <div className="col-lg-8 m-auto text-end contactForm__form">
            <h2 className='contactForm__heading'>تواصل معنا</h2>
            <form onSubmit={handleSubmit} onBlur={handleBlur} method='POST'>
              <div className={`${showErrors ? "mb-2" : "mb-4"}`}>
                <input 
                className={`text-end form-control w-100 ${((errors.name && touched.name) || backEndErrors )? "input-error" : ""}`}
                type="text" 
                name='name' 
                id='name'
                value={values.name}
                onChange={handleChange}
                placeholder='الاسم' 
                />
                {showErrors && <p className='text-danger text-end text-capitalize'>{ errors.name || backEndErrors?.name }</p>}
              </div>

              <div className={`${showErrors ? "mb-2" : "mb-4"}`}>
                <input 
                className={`text-end form-control w-100 ${((errors.email && touched.email) || backEndErrors )? "input-error" : ""}`}
                type="email" 
                name="email" 
                id="email"
                value={values.email}
                onChange={handleChange}
                placeholder='البريد الالكتروني'
                />
                {showErrors && <p className='text-danger text-end text-capitalize'>{errors.email || backEndErrors?.email}</p>}
              </div>

              <div className={`${showErrors ? "mb-2" : "mb-4"}`}>
                <input 
                className={`text-end form-control w-100 ${((errors.phone && touched.phone) || backEndErrors )? "input-error" : ""}`}
                type="number" 
                name="phone" 
                id="phone"
                value={values.phone}
                onChange={handleChange}
                placeholder='رقم الهاتف'
                />
                {showErrors && <p className='text-danger text-end text-capitalize'>{errors.phone || backEndErrors?.phone}</p>}
              </div>

              <div className={`${showErrors ? "mb-2" : "mb-4"}`}>
                <input 
                className={`text-end form-control w-100 ${((errors.subject && touched.subject) || backEndErrors )? "input-error" : ""}`}
                type="text" 
                name="subject" 
                id="subject"
                value={values.subject}
                onChange={handleChange}
                placeholder='عنوان الرسالة'
                />
                {showErrors && <p className='text-danger text-end text-capitalize'>{ errors.subject || backEndErrors?.subject}</p>}
              </div>

              <div className={`${showErrors ? "mb-2" : "mb-4"}`}>
                <textarea
                className={`text-end form-control w-100 ${((errors.message && touched.message) || backEndErrors )? "input-error" : ""}`}
                name="message" 
                id="contactMessage"
                value={values.message}
                onChange={handleChange}
                placeholder='رسالتك'
                ></textarea>
                {showErrors && <p className='text-danger text-end text-capitalize'>{errors.message || backEndErrors?.message}</p>}
              </div>

              <button 
              className='contact__mainSec__form__submitBtn btn' 
              type='submit'
              id="submitBtn"
              name='submitBtn'
              onClick={()=> setShowErrors(true)}
              >
                ارسال
              </button>

            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
