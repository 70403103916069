import React from 'react';
import "./OurVision.css";
import RightTopImg from '../../Components/AbsolutedImgs/RightTopImg';
import RightBottomImg from '../../Components/AbsolutedImgs/RightBottomImg';
import { Col, Row } from 'react-bootstrap';

export default function OurVision({FLine,SLine,ThLine,FOLine,head}) {
  return (
    <div className='ourVisionSec position-relative'>
      <RightBottomImg />
      <div className="ourVisionLeftImg position-absolute">
        <img src="./Imgs/sec 3/pattern.png" className='h-100' alt="Our Vision" />
      </div>
      <div className="container h-100 position-relative">
        <RightTopImg />
        <Row className='ourTextContainer justify-content-end'>
          <Col lg={9} md={9} className='text-end ourTextContent'>
          <h2 className='headSec'>{head ? head : ""}</h2>
            <p className='sectionText'>
                {FLine ? FLine : ""}
              <br />
              <br />
                {SLine ? SLine : ""}
              <br />
              <br />
                {ThLine ? ThLine : ""}
              {
                FOLine ?
                <>
                <br />
                <br />
                  {FOLine}
                </> :
                ""
              }
            </p>
          </Col>
        </Row>
      </div>
    </div>
  )
}
