import React from 'react';

export default function SectionRightImg({alt,src,heading,text}) {
  return (
    <>
      <div className="sectionRightImg">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-6 text-center p-3 branchTextContainer">
              <h2 className='branchHeading mb-4'>{heading ? heading : ""}</h2>
              <p className='branchText'>{text ? text : ""}</p>
            </div>
            <div className="col-md-6 branchImage">
              <img src={src ? src : ""} alt={alt ? alt : ""} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
