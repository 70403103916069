import React, { useEffect, useState } from 'react';
import "./FireSystem.css";
import EveryPageMainSection from '../../Sections/EveryPageMainSection/EveryPageMainSection';
import TopComponent from '../../Components/TopComponent/TopComponent';
import SectionRightImg from '../../Sections/SectionRightImg/SectionRightImg';
import SectionLeftImg from '../../Sections/SectionLeftImg/SectionLeftImg';

export default function FireSystem() {
  const [loading,setLoading] = useState(true);

  useEffect(()=>{
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timeoutId);
  },[loading]);

  return (
    <>
    {
      loading ?
      <div className="loaderContainer">
        <div className="loader"></div>
      </div>
      :
      <>
        <EveryPageMainSection
        heading="تركيب أنظمة الحريق و الإنذار"
        text="تركيب جميع أنظمة الإطفاءتركيب مضخات الحريق بجميع أنواعها واحجامها مع كامل الملحقات من ( محابس، ساعات ، رادات ، رايزر، وصلة الدفاع المدني ، شفاط المياة ) مع التشغيل ."
        textHead="تركيب أنظمة الحريق"
        imgSrc="./Imgs/أنظمة حريق.png"
        />
        <TopComponent />
        <SectionRightImg 
        alt="Warning System"
        src="./Imgs/16.png"
        heading="تركيب شبكة الإنذار"
        text="نقوم بتركيب أنظمة الإطفاء و الإنذار المبكر من الحريق كاملاً و ذلك حسب أنظمة الدفاع المدني المعمول بها في المملكة ."
        />
        <TopComponent />
        <SectionLeftImg 
        alt="Fire Pumps System"
        src="./Imgs/6.png"
        heading="تركيب مضخات الحريق"
        text="تركيب مضخات الحريق بجميع أنواعها واحجامها مع كامل الملحقات من ( محابس، ساعات ، رادات ، رايزر، وصلة الدفاع المدني ، شفاط المياة ) مع التشغيل ."
        />
        <TopComponent />
      </>
    }
    </>
  )
}
