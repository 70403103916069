import React from 'react';
import "./CopyWrite.css";
import { NavLink } from 'react-router-dom';

export default function CopyWrite() {
  return (
    <div className='copyWriteSec text-light text-center fs-6 mt-2'>
      <p>
        كل الحقوق لشركة درة التعمير
        <br />
        <span className='d-flex gap-3 justify-content-center mt-2'>
            برمجة وتصميم
            <NavLink className="nav-link" to="https://instagram.com/web.yarn?igshid=OGQ5ZDc2ODk2ZA==">
                Web Yarn
            </NavLink>
        </span>
      </p>
    </div>
  )
}
