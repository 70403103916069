import React from 'react';
import {Swiper,SwiperSlide} from 'swiper/react';
import {Autoplay} from "swiper/modules";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "./HomeSecSlider.css";

export default function HomeSecSlider() {
  return (
    <Swiper
    modules={[Autoplay]}
    autoplay={{
      delay: 3000,
      disableOnInteraction: true,
      pauseOnMouseEnter: true,
    }}
    slidesPerView={1}
    centeredSlides={true}
    loop={true}
    lazyPreloadPrevNext={true}
    parallax={true}
    className='mySwiperParent'
  >
    <SwiperSlide className='mySwiperSlide'>
      <h2 className="text-end">
        تقديم خدمات متميزة :
      </h2>
      <p className="text-end">
        تهدف الشركة إلى تقديم خدمات عالية الجودة في مجال المقاولات العامة والبناء والترميم وتركيب التكييف المركزي .
      </p>
    </SwiperSlide>
    <SwiperSlide className='mySwiperSlide'>
      <h2 className="text-end">
        الالتزام بالجودة والسلامة :
      </h2>
      <p className="text-end">
        تضع الشركة الجودة والسلامة في المقام الأول، حيث تعمل على اتباع أعلى معايير الجودة في تنفيذ المشاريع واستخدام المواد العالية الجودة .
      </p>
    </SwiperSlide>
    <SwiperSlide className='mySwiperSlide'>
      <h2 className="text-end">
        التكيف مع التقدم التكنولوجي :
      </h2>
      <p className="text-end">
      تسعى شركة درة التعمير للاستفادة من أحدث التقنيات والابتكارات في مجال البناء والترميم وتركيب التكييف المركزي .
      </p>
    </SwiperSlide>
    <SwiperSlide className='mySwiperSlide'>
      <h2 className="text-end">
        الالتزام بالمواصفات البيئية :
      </h2>
      <p className="text-end">
        تولي الشركة اهتمامًا كبيرًا بالاستدامة البيئية، وتعمل على تنفيذ المشاريع بطرق مستدامة وفقًا للمعايير البيئية المحلية والعالمية .
      </p>
    </SwiperSlide>
    <SwiperSlide className='mySwiperSlide'>
      <h2 className="text-end">
        تطوير الكوادر البشرية :
      </h2>
      <p className="text-end">
        تهتم شركة درة التعمير بتطوير وتدريب فريق العمل وتمكينهم من اكتساب المهارات والمعرفة اللازمة لتنفيذ المشاريع بكفاءة .
      </p>
    </SwiperSlide>
    <SwiperSlide className='mySwiperSlide'>
      <h2 className="text-end">
        تحقيق النجاح التجاري :
      </h2>
      <p className="text-end">
        تسعى الشركة إلى تحقيق النجاح التجاري وتطوير سمعتها في سوق المقاولات والبناء .
      </p>
    </SwiperSlide>
  </Swiper>
  );
}
