import React, { useEffect, useState } from 'react';
import "./AboutUs.css";
import AboutHeroSection from '../../Sections/ThirdHeroSectionAbout/AboutHeroSection';
import TopComponent from '../../Components/TopComponent/TopComponent';
import OurVision from '../../Sections/OurVision/OurVision';

export default function AboutUs() {
  const [loading,setLoading] = useState(true);

  useEffect( () => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timeoutId);
  },[loading]);

  return (
    <>
    {
      loading ?
      <div className='loaderContainer'>
          <div className="loader"></div>
      </div>
      :
      <>
        <AboutHeroSection 
        alt="About Main Hero Section"
        src="./Imgs/WhatsApp Image 2023-12-12 at 04.20.14_3205beb6.jpg"
        heading="من نحن"
        text="شركة سعودية رائدة في مجال المقاولات والبناء في المملكة العربية السعودية. تأسست الشركة لتلبية احتياجات السوق المتنامية في قطاع البناء والتطوير العقاري في المملكة ."
        />
        <TopComponent />
        <OurVision
        head="اقرأ المزيد :"
        FLine="نحن نعتبر أنفسنا شركة ملتزمة بتقديم أعلى مستويات الجودة والتميز في كل مشروع نقوم به. نحن نمتلك فريقًا ذو خبرة ومهارات عالية في مجال المقاولات والهندسة المعمارية وإدارة المشاريع. يتميز فريقنا بالاحترافية والكفاءة في تنفيذ المشاريع بدقة وفقًا للمواصفات والمعايير العالمية ."
        SLine="تعمل شركة درة التعمير في مجموعة واسعة من المجالات التي تشمل الإنشاءات السكنية والتجارية والصناعية. قمنا بتنفيذ العديد من المشاريع الكبيرة والمهمة في جميع أنحاء المملكة العربية السعودية، بما في ذلك المباني السكنية والمجمعات التجارية والمستشفيات والمدارس والمباني الحكومية والمشاريع الصناعية ."
        ThLine="نحن نضمن توفير أفضل الحلول والتقنيات البنائية الحديثة لعملائنا، ونلتزم بالالتزام الكامل بالجدول الزمني والميزانية المحددة لكل مشروع. نحن نولي اهتمامًا كبيرًا للابتكار والاستدامة البيئية في جميع عملياتنا، ونعمل على تحقيق التنمية المستدامة في المجتمعات التي نعمل فيها ."
        FOLine="باختصار، نحن في شركة درة التعمير نسعى جاهدين لتحقيق رؤية عملائنا وتلبية توقعاتهم من خلال تقديم أعلى مستويات الجودة والمهنية في مجال المقاولات والبناء ."
        />
        <TopComponent />
      </>
    }
    </>
  );
};
