import React from 'react';
import "./TopComponent.css";

export default function TopComponent() {
  return (
    <div className='w-100 topComponent position-relative'>
      <img src="./Imgs/contact/Contact par.png" className='w-100 h-100' alt="center blue line" />
      <div className="topComponentCenterLine position-absolute">
        <img src="./Imgs/sec 3/inner line for name.png" className='w-100 h-100' alt="center gray line" />
      </div>
      <div className="topComponentPageName position-absolute">
        <p>Dora Altaemir</p>
        
        {/* <img src="./Imgs/sec 3/name for line.png" className='w-100 h-100' alt="page name image" /> */}
      </div>
    </div>
  )
}
