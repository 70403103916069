import React from 'react';
import "./AboutHeroSection.css";
import { Col, Row } from 'react-bootstrap';

export default function AboutHeroSection({heading,text ,src ,alt}){
    return (
        <div className='heroSection'>
            <div className="upperLeftImg position-absolute">
                <img src="./Imgs/sec_1/upper corner.png" alt="upper left" />
            </div>
            <div className="bottomLeftImg position-absolute">
                <img src="./Imgs/sec_1/logo battrn.png" className='h-100' alt="bottom left" />
            </div>
            <div className="centerBottomImg position-absolute">
                <img src="./Imgs/sec_1/shades for ahdaf.png" className='h-100' alt="center bottom" />
            </div>
            <div className="centerTopImg position-absolute">
                <img src="./Imgs/sec_1/Rectangle for hadafna.png" className='h-100' alt="center top" />
            </div>
            <h1 className="heroSectionText text-center">
                درة التعمير
            </h1>
            <div className='container-fluid heroSectionContainer'>
                <Row>
                    <Col lg={6} md={8} className='heroSectionColumnSLider d-flex flex-column align-items-center mt-5'>
                        <h1 className='everyPageMainHeroSectionHeading pt-0 mb-4'>
                            {heading ? heading : ""}
                        </h1>
                        <p className='text-center sectionText'>
                            {text ? text : ""}
                        </p>
                    </Col>
                    <Col lg={2} className='spacingColumn'></Col>
                    <Col lg={4} className='heroSectionColumn heroSectionRightColumn about__ImageHeroSection position-relative'>
                        <img className='mainImg' src={src ? src : ""} alt={alt ? alt : ""} />
                    </Col>
                </Row>
            </div>
        </div>
    );
};
