import React, { useEffect, useState } from 'react';
import "./OwnsManage.css";
import EveryPageMainSection from '../../Sections/EveryPageMainSection/EveryPageMainSection';
import TopComponent from '../../Components/TopComponent/TopComponent';
import SectionRightImg from '../../Sections/SectionRightImg/SectionRightImg';
import SectionLeftImg from '../../Sections/SectionLeftImg/SectionLeftImg';

export default function OwnsManage() {
  const [loading , setLoading] = useState(true);

  useEffect(()=>{
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timeoutId);
  },[loading]);

  return (
    <>
    {
      loading ?
      <div className="loaderContainer">
        <div className="loader"></div>
      </div>
      :
      <>
        <EveryPageMainSection
        heading="ادارة أملاك"
        text="تعمل شركة درة التعمير على تطوير مجموعة متنوعة من المشاريع في قطاع الفنادق والأجنحة الفندقية. تشمل مشاريعها الفنادق الفاخرة والمنتجعات السياحية، وتتميز بتصميمات معمارية فريدة وأنيقة تلبي احتياجات النزلاء من الراحة والترفيه.بالإضافة إلى بناء المشاريع، تقوم شركة درة التعمير أيضًا بإدارة وتشغيل الفنادق والأجنحة الفندقية التي تقوم ببنائها. تهدف الشركة إلى توفير تجربة استثنائية للنزلاء من خلال تقديم خدمات عالية الجودة ومرافق متكاملة ."
        textHead="إدارة الفنادق و الاجنحة الفندقية"
        imgSrc="./Imgs/ادارة أملاك.png"
        />
        <TopComponent />
        <SectionRightImg 
        alt="Residential Buildings"
        src="./Imgs/5.png"
        heading="إدارة العمائر السكنية"
        text="تعمل شركة درة التعمير على تنفيذ وإدارة مجموعة متنوعة من المشاريع السكنية، بدءًا من المباني السكنية الصغيرة إلى المشاريع السكنية الكبيرة والمجمعات السكنية المتكاملة. تهدف الشركة إلى توفير بيئة سكنية مريحة وجذابة للعملاء، وتضمن الحفاظ على معايير الجودة والأمان في جميع جوانب المشروع.بجانب بناء المشاريع السكنية، تقدم شركة درة التعمير خدمات إدارة العقارات السكنية. تهدف الشركة إلى تسهيل حياة السكان وتوفير خدمات متكاملة تشمل الصيانة والأمن والنظافة والإدارة العامة للمباني. تضمن الشركة تشغيل سلس وفعال للعقارات السكنية، مما يوفر راحة للسكان ويسهم في الحفاظ على قيمة العقارات على المدى الطويل ."
        />
        <TopComponent />
        <SectionLeftImg 
        alt="Manage Trading Projects"
        src="./Imgs/12.png"
        heading="إدارة و تشغيل المشاريع التجارية"
        text="تعمل شركة درة التعمير على تنفيذ وإدارة مشاريع تجارية متنوعة، بدءًا من المراكز التجارية والمجمعات التجارية والمحلات التجارية. تهدف الشركة إلى توفير بيئة تجارية متطورة ومتميزة للعملاء، تلبي احتياجاتهم وتعزز نجاح أعمالهم.بالإضافة إلى بناء المشاريع التجارية، تقدم شركة درة التعمير خدمات إدارة وتشغيل المشاريع التجارية. تشمل هذه الخدمات إدارة العقارات التجارية، والصيانة والنظافة، وإدارة الإيجارات والعقود، والتسويق والترويج للمشاريع. تعمل الشركة على توفير بيئة تجارية متناسبة ومناسبة للعملاء، تسهم في نجاح أعمالهم وتعزز عوائد استثماراتهم ."
        />
        <TopComponent />
      </>
    }
    </>
  )
}
