import React from 'react';

export default function RightTopImg() {
    return (
        <>
            <div className="ourMessageRightImg position-absolute">
                <img src="./Imgs/sec 2/Rectangle for resaltna.png" alt="our right" className='w-100 h-100' />
            </div>
        </>
    )
}
