import React, { useEffect, useState } from 'react';
import "./Home.css";
import HeroSection from '../../Sections/HeroSection/HeroSection';
import TopComponent from '../../Components/TopComponent/TopComponent';
import OurMessage from '../../Sections/OurMessage/OurMessage';
import OurVision from '../../Sections/OurVision/OurVision';

export default function Home() {
    const [loading,setLoading] = useState(true);

    useEffect(()=>{
        const timeoutId = setTimeout(() => {
            setLoading(false);
        }, 500);
    return () => clearTimeout(timeoutId);
    },[loading]);

    return (
        <>
        {
            loading ?
            <div className='loaderContainer'>
                <div className="loader"></div>
            </div> 
            :
            <>
                <HeroSection />
                <TopComponent />
                <OurMessage />
                <TopComponent />
                <OurVision
                head="رؤيتنا :"
                FLine="رؤيتنا هي أن نصبح رائدين في صناعة المقاولات العامة، وأن نكون الخيار الأفضل لعملائنا في جميع أنحاء السوق. نسعى جاهدين لتحقيق التفوق والتميز في كل جانب من جوانب عملنا ."
                SLine="نحن نسعى لتقديم أعلى مستويات الجودة والموثوقية في جميع مشاريعنا. نحن نسعى لتحقيق أعلى معايير الأداء والتميز في كل مشروع نقوم به، سواء كان صغيرًا أو كبيرًا. نحن نضمن أن نكون على اطلاع دائم على أحدث التقنيات والممارسات الصناعية، ونعتمد على فريق عمل مؤهل ومتخصص يتمتع بالمهارات اللازمة لتنفيذ المشاريع بكفاءة وجودة عالية ."
                ThLine="نحن نسعى أيضًا لبناء علاقات قوية ومستدامة مع عملائنا وشركائنا. نحن نؤمن بأهمية التواصل الجيد والاستماع الى احتياجات العملاء وتوقعاتهم. نحن نعمل على تقديم حلول مخصصة ومبتكرة تلبي تلك الاحتياجات وتفوق توقعاتهم. نحن نهتم برضا العملاء ونعمل بجد للحفاظ على سمعتنا الممتازة كشركة موثوقة ومحترفة."
                />
                <TopComponent />
            </>
        }
            
        </>
    );
};
