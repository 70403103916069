import React, { useEffect, useState } from 'react';
import "./Buildings.css";
import EveryPageMainSection from '../../Sections/EveryPageMainSection/EveryPageMainSection';
import SectionLeftImg from '../../Sections/SectionLeftImg/SectionLeftImg';
import TopComponent from '../../Components/TopComponent/TopComponent';
import SectionRightImg from '../../Sections/SectionRightImg/SectionRightImg';

export default function Buildings() {
  const [loading,setLoading] = useState(true);

  useEffect(()=>{
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timeoutId);
  },[loading]);

  return (
    <>
    {
      loading ?
      <div className='loaderContainer'>
        <div className="loader"></div>
      </div> 
      :
      <>
        <EveryPageMainSection
        heading="المباني السكنية"
        text="مقاول خرسانة بمكة المكرمة  متخصص في تنفيذ جميع أعمال المقاولات التي يقدمها عبر شركتنا مع تقديم أسعار مناسبة وتكلفة معقولة تجعلنا نحظى برضا عملائنا ،"
        textHead=" العظم  او الهياكل الخرسانية"
        imgSrc="./Imgs/Untitled-100_04.png"
        />
        <TopComponent />
        <SectionRightImg
        alt="Electrical Service" 
        src="./Imgs/Untitled-100_07.png" 
        heading="أعمال التاسيس للكهرباء و التمديدات"
        text="من بين المزايا الهامة التي تتوفر فحص وصيانة كهرباء المنازل وشبكات الحمامات بمكة هو توافر الكثير من الفنيين المتخصصين في فحص اعطال الكهرب تحت الجدران والقيام بكافة أعمال تمديد الاسلاك وغيرها من الأمور التي تتعلق بشبكه الكهرباء والتلفون والانترنت بشكل عام. كما تمتلك مقاولات كهرباء الشرقية فحص وصيانة كهرباء منازل الكثير من المعدات المميزة والحديثة التي يتمكن العاملين لدى مقوال كهرباء من خلالها التعرف على أماكن اعطال الكهرب وانقطاع التيار بدقة كبيرة ."
        />
        <TopComponent />
        <SectionLeftImg 
        alt="Mechanics and Plumbing Service"
        src="./Imgs/Untitled-100_09.png" 
        heading="اعمال تأسيس السباكة و الاعمال الميكانيكية"
        text=" للأعمال الكهروميكانيكية والسباكة خدمات ذات جودة عالية في كل من التصميم والهندسة والتوريد والتركيب والفحص لكافة الأعمال الميكانيكية والكهربائية والسباكة المرتبطة بالمشاريع الصناعية والتجارية والسكنية والمشافي والأبراج ."
        />
        <TopComponent />
        <SectionRightImg
        alt="Finishing Service"
        src="./Imgs/Untitled-100_14.png" 
        heading="التشطيب"
        text="نقوم في تشطيب للديكور والتشطيبات بتنفيذ احدث الديكورات في التصميم التي تلائم متطلباتك وذوقك الفريد .​"
        />
        <TopComponent />
        <SectionLeftImg 
        alt="Floors , marble and ceramics Services"
        src="./Imgs/Untitled-100_18.png" 
        heading="ارضيات    (سراميك   . برسلان . رخام )"
        text="يواكب منزل السيراميك آخر التطورات والمنتجات المبتكرة في مجال البناء. عملاؤنا هم محور اهتمام المؤسسة ، لذلك نسعى دائمًا لإرضاء عملائنا وتمييز أنفسهم عن الآخرين. تعتبر منزل السيراميك رائدة في تقديم منتجات وحلول ."
        />
        <TopComponent />
        <SectionRightImg
        alt="Isolation Service"
        src="./Imgs/Untitled-100_21.png" 
        heading="عزل"
        text=" عزل الاسطح من الامور الضروريه للغايه في وقتنا هذا- حيث ان العزل يمنع وصول درجات الحراره العاليه لداخل المبني كما يمنع دخول الماء للمباني وحدوث ضرر بها -عليك الاعتماد علي مؤسسة الجواد  كلين للتنظيف -والتي ستقدم لكم خدمه عزل فائقه الجوده مع اقل سعر ممكن لن تجد له مثيل في مكان اخر  اتصل الان للحصول علي اقوي الخصومات .​"
        />
        <TopComponent />
        <SectionLeftImg
        alt="Wall Paper Service"
        src="./Imgs/Untitled-100_11.png" 
        heading="ورق حائط"
        text="نحن نعرف بأنك تسعى لأن تجعل من منزلك أو مكتبك مساحة جميلة ومريحة تلبي احتياجاتك، وتعكس ذوقك الرفيع، وتعجب كل من يزورك، ونقدر أنك أمام كم هائل من الخيارات التي يمكن أن تفكر فيها، فهل ستغير الأثاث أم الستائر أم التحف أم لون طلاء الجدران.. وماذا عن تكاليف هذه المواد وصعوبات الحصول عليها بشكل سريع ومريح ." 
        />
        <TopComponent />
        <SectionRightImg
        alt="Paintings Services"
        src="./Imgs/Untitled-100_23.png" 
        heading="الدهانات"
        text="اختيار دهان المنزل أهم ما يميز بيتتك ويعبر عن شخصيتك وعن ذوقك وذوق العائلة، ولذلك لابد أن تبحث عن أفضل الطرق التي تضفي اللمسات الجمالية الجذابة التي تجعلك تتفاخر به أمام أقاربك ومعارفك وضيوفك، وإذا كنت تبحث عن أفضل شركة دهانات بالرياض فإننا نقدم لك أجود خدمة في استخدام كل أنواع الطلاء والدهانات الحديثة، مع تصميم ديكورات فنية رفيعة المستوى بالاعتماد على أمهر الفنيين والمهندسين المتخصصين، وسنقدم لك من خلال هذه المقالة كل ما يتعلق بخدماتنا وطرق التواصل معنا وأسعارنا التنافسية التي لن تجدها في أي مكان آخر."
        />
        <TopComponent />
      </>
    }
    </>
  )
}
