import React, { useEffect, useState } from 'react';
import "./Roads.css";
import EveryPageMainSection from '../../Sections/EveryPageMainSection/EveryPageMainSection';
import TopComponent from '../../Components/TopComponent/TopComponent';
import SectionRightImg from '../../Sections/SectionRightImg/SectionRightImg';
import SectionLeftImg from '../../Sections/SectionLeftImg/SectionLeftImg';

export default function Roads() {
  const [loading,setLoading] = useState(true);

  useEffect(()=>{
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timeoutId);
  },[loading]);

  return (
    <>
      {
        loading ?
        <div className="loaderContainer">
          <div className="loader"></div>
        </div>
        :
        <>
          <EveryPageMainSection
          heading="الطرق"
          text="نحن نوفر طبقة ما تحت الأساس بدرجات وأحجام وألوان مختلفة. منتجاتنا من Sub Base ذات جودة عالية ويتم توفيرها في مقطورة 3 ، 20 ،35، 45 متر مكعب ."
          textHead="صب بيس"
          imgSrc="./Imgs/الطرق.png"
          />
          <TopComponent />
          <SectionRightImg 
          alt="Asphalt"
          src="./Imgs/15.png"
          heading="اسفلت"
          text="مصانع الأسفلت لبناء الطرق يتطلب تصنيع الأحجار المغطاة المطلية مزيجًا من عدد من الركام ، والرمل والحشو (مثل الغبار الحجري) ، بالنسب الصحيحة ، المسخنة ، وأخيراً المغلفة مع مادة رابطة ، عادة ما تكون مستندة إلى القار أو ، في بعض الحالات القطران ، على الرغم من تمت إزالة القطران من BS4987 وليس المشار إليها في BSEN 13108/1. يجب أن تكون درجة حرارة المنتج النهائي كافية لتكون قابلة للتطبيق بعد النقل إلى الوجهة النهائية. درجة الحرارة في حدود 100 إلى 200 درجة مئوية أمر طبيعي. البلدان لديها ."
          />
          <TopComponent />
          <SectionLeftImg 
          alt="SideWalks"
          src="./Imgs/2.png"
          heading="ارصفة  ( بردورات و الانترلوك )"
          text="نقوم بتوريد وتركيب - انترلوك- بردورة-بلاط اسمنتي-حواجز خرسانية-مصدات مواقف-بلاط مزايكو ."
          />
          <TopComponent />
        </>
      }
    </>
  )
}
