import React, { useEffect, useState } from 'react';
import "./FixAndClean.css";
import EveryPageMainSection from '../../Sections/EveryPageMainSection/EveryPageMainSection';
import TopComponent from '../../Components/TopComponent/TopComponent';
import SectionRightImg from '../../Sections/SectionRightImg/SectionRightImg';
import SectionLeftImg from '../../Sections/SectionLeftImg/SectionLeftImg';

export default function FixAndClean() {
  const [loading,setLoading] = useState(true);

  useEffect(()=>{
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timeoutId);
  },[loading]);

  return (
    <>
      {
        loading ? 
        <div className='loaderContainer'>
          <div className="loader"></div>
        </div>
        :
        <>
          <EveryPageMainSection
          heading="صيانة ونظافة وتشغيل"
          text="درة التعمير.  لها باع في اعمال النظافة  و التي اثبتت انها من افضل شركه تنظيف , لما لديها عمالة متخصصة و متدربة في المواقع التجارية و المولات و المواقع الخارجية و  لما لها من اهميه قصوي في تنظيف المنازل والشقق والبيوت و خزنات المياه  , مع التعقيم والتطهير من الفيروسات والبكتريا والتخلص من الاتربه والاوساخ ,فا اذا كنت تبحث عن خدمات تنظيف  اليكم مؤسسة درة التعمير في تنظيف وتعقيم المنشأت وتوفر لكم حمايه لا حدود لها وتدووم لاوقات طويله مع افضل العمالة المدربة . "
          textHead="توفير عمالة نظافة مدربين"
          imgSrc="./Imgs/صيانة ونظافة و تشغيل.png"
          />
          <TopComponent />
          <SectionRightImg 
          alt="Fixing Example"
          src="./Imgs/7.png"
          heading="اعمال الصيانة"
          text="تمتلك الشركة عدد كبير من الفنيين والنجارين الذين يقومون بصيانة وتصليح كامل وشامل للمبنى ,و الكشف والفحص عن الأسلاك والكابلات الكهربائية المتواجدة داخل المبنى والتحقق من سلامة وحماية المبنى و الكشف عن أماكن التسربات الأرضيات والجدران والأسقف والعمل على معالجتها بشكل تام وجيد.  و صيانة وترميم لجميع أرضيات الحمام والمطبخ والتخلص من مشاكل الرطوبة، أو أي تسريب للمياه . "
          />
          <TopComponent />
          <SectionLeftImg 
          alt="Restoration Works"
          src="./Imgs/11.png"
          heading="اعمال الترميم"
          text="تغيير وتبديل جميع الأرضيات المتهالكة والمصابة بالضرر، تصليح الأدوات ومواسير الصرف الصحي والقيام باستبدال القطع التالفة بأخرى جديدة وقوية. القيام بعلاج أي تسربات المياه والتخلص من الرطوبة بشكل نهائي المتواجدة في المطابخ والحمام ترميم واجهة منزل القديمة والمتهالكة ."
          />
          <TopComponent />
          <SectionRightImg 
          alt="Trading Places"
          src="./Imgs/10.png"
          heading="تشغيل المواقع التجارية"
          text="تشغيل وتسويق الفنادق و الشقق المفروشة وتحويل الوحدات سكنيه عادية الى مبنى يقدم خدمات الفنادق العالمية المتميزة  كذلك نقوم بتشغيل المواقع الجديده من التأسيس الى التأثيث والتوظيف والتدريب تحت إشراف فريق عملنا المختصين بالمجال ونقدم خطة تشغيل إستراتيجية قبل البدء بالعملليكون المالك على اطلاع بسير العمل قبل البدء وكذلك دراسة جدوى ماليه واقعية لأول خمس سنوات بطريقتنا في ايجاد معطيات الموقع  وتشغيل وصيانة محطات الوقود ."
          />
          <TopComponent />
        </>
      }
    </>
  );
};
