import React from 'react';
import "./OurMessage.css";
import RightTopImg from '../../Components/AbsolutedImgs/RightTopImg';
import RightBottomImg from '../../Components/AbsolutedImgs/RightBottomImg';
import { Col, Row } from 'react-bootstrap';

export default function OurMessage() {
  return (
    <div className='ourMessage position-relative'>
      <div className="ourMessageLeftImg position-absolute">
        <img src="./Imgs/logo.jpg" alt="our left" className='w-100 h-100' />
      </div>
      <RightBottomImg />
      <div className="container h-100 position-relative">
        <RightTopImg />
        <Row className='justify-content-end ourTextContainer'>
          <Col lg={9} md={9} className='text-end ourTextContent'>
            <h2 className='headSec'>رسالتنا :</h2>
            <p className='sectionText'>
              لقد أثبتت شركة درة التعمير نفسها كواحدة من الشركات الرائدة في هذا المجال، وذلك من خلال سجل حافل بالمشاريع الناجحة والعملاء المرتاحين.
              <br />
              <br />
              تتميز شركتكم بالمهنية والكفاءة في تنفيذ المشاريع، حيث تعملون بجدية واجتهاد لتحقيق أعلى معايير الجودة والموثوقية. مهارات فريقكم المختص وتجربتهم الواسعة في مجال المقاولات تساعدكم على تنفيذ المشاريع بنجاح، سواء كانت صغيرة أو كبيرة، وتلبية احتياجات العملاء بأفضل الطرق الممكنة.
              <br />
              <br />
              تشتهر شركة درة التعمير بالالتزام القوي بالمواعيد والجدول الزمني للمشاريع. فأنتم تدركون أهمية تسليم المشاريع في الوقت المحدد وفقاً لتوقعات العملاء. هذا الالتزام المتواصل بالجودة والموثوقية يساعدكم على بناء ثقة قوية مع العملاء وتحقيق رضاهم التام.
            </p>
          </Col>
        </Row>
      </div>
    </div>
  )
}
