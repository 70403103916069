import React, { useEffect, useState } from 'react';
import "./TradePlaces.css";
import EveryPageMainSection from '../../Sections/EveryPageMainSection/EveryPageMainSection';
import TopComponent from '../../Components/TopComponent/TopComponent';
import SectionRightImg from '../../Sections/SectionRightImg/SectionRightImg';
import SectionLeftImg from '../../Sections/SectionLeftImg/SectionLeftImg';

export default function TradePlaces() {
  const [loading,setLoading] = useState(true);

  useEffect(()=>{
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timeoutId);
  },[loading]);

  return (
    <>
    {
      loading ?
      <div className='loaderContainer'>
          <div className="loader"></div>
      </div>
      :
      <>
        <EveryPageMainSection
        heading="المواقع تجارية"
        text="تقدم شركة درة التعمير مجموعة واسعة من الخدمات المتخصصة في إنشاء المطاعم. يتم تنفيذ العمليات الإنشائية بواسطة فرق مؤهلة وذات خبرة عالية في المجال. يتم تنفيذ الأعمال بدقة وفقًا للمواصفات والمعايير العالمية، مع الالتزام بالجدول الزمني المحدد والميزانية المحدودة ."
        textHead="انشاء مطاعم"
        imgSrc="./Imgs/مواقع تجارية.png"
        />
        <TopComponent />
        <SectionRightImg 
        alt="Fuel Stations"
        src="./Imgs/4.png"
        heading="انشاء محطات البنزين"
        text={`تهدف شركة درة التعمير إلى تلبية احتياجات عملائها وتحقيق رؤيتهم في إنشاء محطات البنزين الحديثة والمبتكرة. تعمل الشركة على تنفيذ المشاريع بدقة وفقًا للمعايير الفنية والقوانين البيئية والأمان، وتضمن توافر جميع المرافق الضرورية والتجهيزات اللازمة لتشغيل المحطة بكفاءة عالية.تقدم شركة درة التعمير مجموعة واسعة من الخدمات المتخصصة في إنشاء محطات البنزين. تشمل هذه الخدمات تصميم وتنفيذ المنشآت الرئيسية للمحطة مثل مضخات الوقود والقاعات الخدمية ومحطات الغسيل والورش، بالإضافة إلى تركيب وتجهيز أنظمة الإضاءة والتهوية والتبريد والحماية من الحرائق .`}
        />
        <TopComponent />
        <SectionLeftImg 
        alt="Build Marketing places"
        src="./Imgs/12.png"
        heading="انشاء أسواق تجارية"
        text={`تقدم شركة درة التعمير مجموعة واسعة من الخدمات المتخصصة في إنشاء الأسواق التجارية. تشمل هذه الخدمات تصميم وتنفيذ المباني والهياكل الداخلية والخارجية للأسواق، بما في ذلك الواجهات الزجاجية وأنظمة الإضاءة والتهوية والتبريد. تهتم الشركة بتوفير تصاميم مرنة وقابلة للتعديل لتناسب احتياجات العملاء وتمكينهم من تحقيق رؤيتهم التجارية .تعتمد شركة درة التعمير على استخدام أحدث التقنيات والمواد في إنشاء الأسواق التجارية. تضمن الشركة استخدام مواد عالية الجودة وصديقة للبيئة، بالإضافة إلى تنفيذ الأعمال بدقة وفقًا للمعايير الفنية والقوانين المحلية .`}
        />
        <TopComponent />
      </>
    }
    </>
  );
};
